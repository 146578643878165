const getJobRuntime = job => {
  if (!job.Processed
  || job.Processed.runTime === undefined
  || job.Processed.runTime === null) {
    return '-';
  }

  const total = Math.round(job.Processed.runTime / 1000);
  const h = Math.floor(total / 3600);
  const m = Math.floor((total % 3600) / 60);
  const s = Math.floor((total % 3600) % 60);

  let str = `${s}s`;
  if (h || m) {
    str = `${m}m ${str}`;
    if (h) {
      str = `${h}h ${str}`;
    }
  }

  return str;
};

export default getJobRuntime;

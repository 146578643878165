export default {
  FASTA: {
    characters: 'AGCMSTEDQNRKHPLVIYWF',
    minLength: 7,
    maxLength: 500,
  },
  Specificity: {
    type: 'radio',
    label: 'Algorithm',
    values: [
      {
        default: true,
        label: 'Best overall prediction',
        value: '99',
      },
      {
        label: 'High specificity prediction',
        value: '100',
      },
      {
        label: 'High sensitivity prediction',
        value: '91',
      },
      {
        label: 'Best per sequence',
        value: 'best',
      },
      {
        custom: {
          label: '0 to 100...',
          type: 'number',
          min: 0,
          max: 100,
        },
        label: 'Custom specificity',
        value: '',
      },
    ],
  },
};

import React from 'react';
import { func, node, string } from 'prop-types';

import JobList from '../Components/Job/List';
import Viewport from '../Components/Viewport';

import useAuth from '../Hooks/useAuth';

const Dashboard = ({ children, details, title }) => {
  const { AuthSession } = useAuth();
  const email = AuthSession?.attributes?.email;

  return (
    <Viewport title={title}>
      {email && (
        <p className='Viewport__content-username'>{`Hello ${email}!`}</p>
      )}
      {children}
      <JobList details={details} />
    </Viewport>
  );
};

Dashboard.propTypes = {
  children: node,
  details: func,
  title: string
};

Dashboard.defaultProps = {
  children: null,
  details: null,
  title: ''
};

export default Dashboard;

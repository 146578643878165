import React, { useState } from 'react';
import {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { RadioGroup } from '@material-ui/core';

import FormRadioOption from './RadioOption';

const FormRadioGroup = ({
  name,
  onChange,
  value,
  values,
}) => {
  const [ customValue, setCustomValue ] = useState((values.find(item => item.custom) || {}).value);

  const handleOnChange = newValue => {
    setCustomValue(newValue);
    onChange(newValue);
  };

  return (
    <RadioGroup
      name={ name }
      onChange={ event => onChange(event.target.value) }
      value={ value }
    >
      { values.map(item => (
        <FormRadioOption
          { ...item }
          key={ `option-${name}-value-${item.value}` }
          selected={ value === (item.custom ? customValue : item.value) }
          onChange={ handleOnChange }
          value={ item.custom ? customValue : item.value }
        />
      )) }
    </RadioGroup>
  );
};

FormRadioGroup.propTypes = {
  name: string.isRequired,
  onChange: func,
  value: oneOfType([ number, string ]),
  values: arrayOf(shape({
    custom: shape({}),
  })).isRequired,
};

FormRadioGroup.defaultProps = {
  onChange: () => {},
  value: '',
};

export default FormRadioGroup;

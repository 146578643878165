import React from 'react';
import Markdown from 'react-markdown';

import Dashboard from 'frontend-common/src/Screens/Dashboard';

import jobsStatus from '../content/screens/jobs_status.json';

export default () => (
  <Dashboard title={ jobsStatus.title }>
    <Markdown className="Viewport__content-bodyText">{ jobsStatus.body }</Markdown>
  </Dashboard>
);

import numberConverter from 'number-to-words';
import { node, number, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  TextField
} from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from "@material-ui/core/Switch";

import FormRadioGroup from '../Components/Form/RadioGroup';
import Viewport from '../Components/Viewport';

import useJobs from '../Hooks/useJobs';

import useAuth from '../Hooks/useAuth';
import promiseState from '../utils/promiseState';

const NewJob = ({ children, options, title }) => {
  // const upload = useRef(null);
  const [caret, setCaret] = useState(-1);
  const [jobTitle, setJobTitle] = useState('');
  const [optionItems, setOptionItems] = useState([]);
  const [optionValues, setOptionValues] = useState({});
  const [sequence, setSequence] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isBlocked } = useAuth();

  const sequenceRef = useRef(null);
  const submitRef = useRef(null);

  const history = useHistory();
  const jobs = useJobs();

  useEffect(() => {
    const newOptionItems = Object.keys(options)
      .filter((name) => name !== 'FASTA')
      .map((name) => ({
        name,
        ...options[name]
      }));

    setOptionItems(newOptionItems);
  }, [options, setOptionItems]);

  useEffect(() => {
    const newOptionValues = optionItems.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: (cur.values?.find((value) => value.default) || {}).value || cur.defaultValue,
      }),
      {}
    );

    setOptionValues(newOptionValues);
  }, [optionItems]);

  // Preserve caret position on controlled input with processed value.
  useEffect(() => {
    if (caret !== -1) {
      if (sequenceRef.current) {
        sequenceRef.current.selectionStart = caret;
        sequenceRef.current.selectionEnd = caret;
      }
      setCaret(-1);
    }
  }, [caret]);

  const { FASTA } = options;
  const validSequence = FASTA && new RegExp(
    `^[${FASTA.characters}]{${FASTA.minLength},${FASTA.maxLength}}`
  );
  const validateFields = () => !!jobTitle
    && (!validSequence || sequence.match(validSequence))
    && optionItems.every((option) => !option?.validate || option.validate(optionValues[option.name]));

  const handleTitleChange = (event) => {
    const { value } = event.target;
    setJobTitle(value);
  };

  const handleSequenceChange = (event) => {
    const { selectionStart, value } = event.target;

    const validCharacters = new RegExp(`[^${FASTA.characters}]`, 'g');

    setSequence(value.toUpperCase().replace(validCharacters, ''));
    setCaret(selectionStart);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (isBlocked || !validateFields()) return;

    // No multiple submits
    const state = await promiseState(jobs.newJob);
    if (state === 'pending') {
      return;
    }

    const newJob = {
      Title: jobTitle,
      ...optionValues,
    };

    if (FASTA) {
      newJob.Sequence = sequence;
    }

    const res = await jobs.create(newJob);

    if (res) {
      setIsSubmitting(false);
      // Go back to updated dashboard.
      jobs.update();
      history.push('dashboard');
    }
  };

  const minString = FASTA && numberConverter.toWords(FASTA.minLength);
  const maxString = FASTA && numberConverter.toWords(FASTA.maxLength);
  const validString = `Valid sequences are a combination between ${minString} to ${maxString} characters long of the following characters: `;

  return (
    <Viewport className='NewJob' title={title} dashboardBtn logoutBtn>
      <form className='NewJob__form' onSubmit={handleSubmit}>
        <div>
          <TextField
            className='NewJob__title'
            label='Title for this job...'
            margin='normal'
            name='title'
            onChange={handleTitleChange}
            type='text'
            value={jobTitle}
            variant='outlined'
          />
        </div>

        { !!FASTA && (
          <>
            <div>
              <TextField
                className='NewJob__sequence'
                inputProps={{
                  maxLength: FASTA.maxLength
                }}
                inputRef={sequenceRef}
                label='Protein sequence...'
                margin='normal'
                multiline
                name='sequence'
                onChange={handleSequenceChange}
                rows={FASTA.maxLength > 50 ? 4 : 2}
                type='text'
                value={sequence}
                variant='outlined'
              />
            </div>
            <div className='NewJob__instructions'>
              {validString}
              <span className='NewJob__instructions__code'>{FASTA.characters}</span>
            </div>
          </>
        )}

        {optionItems.map((option) => {
          if (!(option.name in optionValues)) {
            return null;
          }

          switch (option.type) {
            case 'radio':
              return (
                <div key={`option-${option.name}`} className='form__group'>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>{option.label}</FormLabel>

                    <FormRadioGroup
                      onChange={(value) =>
                        setOptionValues({
                          ...optionValues,
                          [option.name]: value
                        })
                      }
                      value={optionValues[option.name]}
                      {...option}
                    />
                  </FormControl>
                </div>
              );

            case 'list':
              return (
                <div key={`option-${option.name}`} className='form__group'>
                  <FormControl component='fieldset'>
                    <InputLabel id={`list-${option.name}`}>
                      {option.label}
                    </InputLabel>

                    <Select
                      labelId={`list-${option.name}`}
                      onChange={(event) =>
                        setOptionValues({
                          ...optionValues,
                          [option.name]: event.target.value
                        })
                      }
                      value={
                        optionValues[option.name] ||
                        options[option.name].default ||
                        ''
                      }>
                      {options[option.name].values.map((value) => (
                        <MenuItem
                          className='no-list-style'
                          key={`${option.name}-${value}`}
                          value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              );

            case 'upload':
              return (
                <div key={`option-${option.name}`} className='form__group'>
                  <TextField
                    type='file'
                    className='NewJob__upload'
                    name={option.name}
                    label={option.label}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept: option.accept,
                    }}
                    onChange={ (event) => {
                      setOptionValues({
                        ...optionValues,
                        [option.name]: event.target.files[0],
                      });
                    }}
                  />
                </div>
              );

            case 'toggle':
              return (
                <div key={`option-${option.name}`} className='form__group'>
                  <FormControl component='fieldset'>
                    <FormControlLabel
                      control= {
                        <Switch
                          checked={ optionValues[option.name] }
                          color="secondary"
                          name={option.name}
                          onChange={(event) => {
                            setOptionValues({
                              ...optionValues,
                              [option.name]: event.target.checked
                            });
                          } }
                        />
                      }
                      label={option.label}
                    />
                  </FormControl>
                </div>
              );

            case 'number':
              return (
                <div key={`option-${option.name}`} className='form__group'>
                  <TextField
                    type='number'
                    className='NewJob__number'
                    name={option.name}
                    label={option.label}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: option.min,
                      max: option.max,
                    }}
                    onChange={(event) => {
                      const { value } = event.target;
                      setOptionValues({
                        ...optionValues,
                        [option.name]: value
                      });
                    }}
                    value={optionValues[option.name]}
                  />
                </div>
              );

            default:
              return null;
          }
        })}

        <div>
          <Button
            className='NewJob__submit'
            color='primary'
            disabled={isSubmitting || isBlocked || !validateFields()}
            onClick={handleSubmit}
            ref={submitRef}
            variant='contained'>
             {isSubmitting? 'Submitting...' : 'Submit new job'}
          </Button>
        </div>

        {children}
      </form>
    </Viewport>
  );
};

NewJob.propTypes = {
  children: node,
  options: shape({
    FASTA: shape({
      characters: string,
      maxLength: number
    })
  }).isRequired,
  title: string
};

NewJob.defaultProps = {
  children: null,
  title: ''
};

export default NewJob;

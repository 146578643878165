import React from 'react';

import { Link } from '@material-ui/core';

const JobFiles = ({ files }) => {
  const show = files
    .filter(file => file.key.endsWith('.zip'));

  if (!show.length) {
    return '-';
  }

  return show.map(file => (
    <Link
      href={ file.url }
      key={ `file-link-${file.key}` }
    >
      Download Job Results
    </Link>
  ));
};

export default JobFiles;

import React from 'react';
import {
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import classnames from 'classnames';

import {
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

const FormRadioOption = ({
  custom,
  label,
  onChange,
  selected,
  value,
}) => (
  <FormControlLabel
    control={ <Radio /> }
    label={ (
      <>
        { label }
        { custom && selected && (
          <TextField
            className={ classnames('form__radio-input-text', `form__radio-input-text--${custom.type}`) }
            margin="normal"
            onChange={ event => onChange(event.target.value) }
            value={ value }
            inputProps={ custom }
            { ...custom }
          />
        ) }
      </>
    ) }
    value={ value }
  />
);

FormRadioOption.propTypes = {
  custom: shape({
    type: string,
  }),
  label: node,
  onChange: func,
  selected: bool,
  value: oneOfType([ number, string ]),
};

FormRadioOption.defaultProps = {
  custom: null,
  label: null,
  onChange: () => {},
  selected: false,
  value: '',
};

export default FormRadioOption;

import React from 'react';
import Markdown from 'react-markdown';

import Home from 'frontend-common/src/Screens/Home';

import landingPage from '../content/screens/landing_page.json';

export default () => (
  <Home
    logo="../static/media/limbo_logo.svg"
    title={ landingPage.title }
  >
    <Markdown>{ landingPage.body }</Markdown>
  </Home>
);

import React from 'react';
import { func, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';

import JobFiles from './Files';

import formatDate from '../../utils/formatDate';
import getJobState from '../../utils/getJobState';
import getJobRuntime from '../../utils/getJobRuntime';

const JobRow = ({ details, job }) => {
  const history = useHistory();
  const state = getJobState(job);

  return (
    <TableRow className="JobList__row" hover>
      <TableCell className="JobList__row__titleCell">
        {
          details && state === 'Finished'
            ? (
              <Link
                href={ details(job.JobId) }
                onClick={ e => {
                  history.push(details(job.JobId));
                  e.preventDefault();
                } }
              >
                { job.Payload.Title }
              </Link>
            )
            : job.Payload.Title
        }
      </TableCell>
      <TableCell className="JobList__row__dateCell">{ formatDate(job.Created) }</TableCell>
      <TableCell>{ state }</TableCell>
      <TableCell align="center">{ getJobRuntime(job) }</TableCell>
      <TableCell align="center">
        <JobFiles files={ job.Results } />
      </TableCell>
    </TableRow>
  );
};

JobRow.propTypes = {
  details: func,
  job: shape({
    JobId: string,
  }).isRequired,
};

JobRow.defaultProps = {
  details: null,
};

export default JobRow;

import React, { memo, useEffect, useState } from 'react';
import { func } from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import JobRow from './Row';

import useJobs from '../../Hooks/useJobs';

const JobList = ({ details }) => {
  const jobs = useJobs();
  const [jobList, setJobList] = useState([]);

  const { all, list } = jobs;

  useEffect(() => {
    (all || list()).then(setJobList);
  }, [all, list]);

  return (
    <Paper className='JobList__wrapper'>
      <Table>
        <TableHead className='JobList__header'>
          <TableRow>
            <TableCell className='JobList__header-cell'>Title</TableCell>
            <TableCell className='JobList__header-cell'>Created</TableCell>
            <TableCell className='JobList__header-cell'>State</TableCell>
            <TableCell className='JobList__header-cell'>Runtime</TableCell>
            <TableCell className='JobList__header-cell'>Results</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobList?.length ? (
            jobList
              ?.slice()
              ?.sort((a, b) => b.Created - a.Created)
              ?.map((job) => (
                <JobRow key={`job-${job.JobId}`} details={details} job={job} />
              ))
          ) : (
            <TableRow>
              <TableCell align='center' colSpan={6}>
                You have not submitted any jobs yet.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

JobList.propTypes = {
  details: func
};

JobList.defaultProps = {
  details: null
};

// The configurator provides us the basic login and sign up screens.
export default memo(JobList);

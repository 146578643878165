import React from 'react';
import Markdown from 'react-markdown';

import Viewport from 'frontend-common/src/Components/Viewport';

import about from '../content/screens/about.json';

export default () => (
  <Viewport
    title={ about.title }
    dashboardBtn
    logoutBtn
    className="About__wrapper"
  >
    <Markdown>{ about.body }</Markdown>
  </Viewport>
);

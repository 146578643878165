import React from 'react';
import Markdown from 'react-markdown';

import NewJob from 'frontend-common/src/Screens/NewJob';

import newJob from '../content/screens/new_job.json';
import options from '../options';

// The configurator provides us the basic login and sign up screens.
export default () => (
  <NewJob title={ newJob.title } options={ options }>
    <Markdown className="NewJob__standalone">{ newJob.body }</Markdown>
  </NewJob>
);
